import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import invariant from "tiny-invariant";
import { ErrorBoundary } from "react-error-boundary";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AppErrorBoundary from "./AppErrorBoundary";

const queryClient = new QueryClient();
const container = document.getElementById("root");
invariant(container);
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <ErrorBoundary FallbackComponent={AppErrorBoundary}>
        <App />
      </ErrorBoundary>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
